import fetch from 'cross-fetch';
import {ApolloLink, ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        withCredentials: true,
        headers: {
            ...headers,
            'x-api-key': 'c8c52a4563b9bd8aadc2a247919ff10c'
        }
    }));

    return forward(operation);
})


export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            SearchResult: {
                keyFields: ["id", "language"]
            },
            Accommodation: {
                keyFields: ["globalId", "language"],
                fields: {
                    hyperObjects: {
                        merge(existing, incoming, { readField, mergeObjects }) {
                            // HyperObject "id" is the URL + price since that is what we display
                            const getId = (obj) => `${readField('url', obj)}|${readField('price', obj)}`

                            const merged = existing?.slice(0) ?? []

                            // Map from HyperObject "id" to index in the existing array
                            const idToIndex = { }
                            if( existing ) {
                                existing.forEach((hyperObject, index) => {
                                    idToIndex[getId(hyperObject)] = index
                                })
                            }

                            // Merge the incoming hyperObjects into the existing array
                            incoming.forEach(hyperObject => {
                                const id = getId(hyperObject)
                                const index = idToIndex[id]
                                if( typeof index === 'number' ) {
                                    // Merge the new hyperObject data with the existing one
                                    merged[index] = mergeObjects(merged[index], hyperObject)
                                } else {
                                    // First time we've seen this hyperObject in this array.
                                    idToIndex[id] = merged.length
                                    merged.push(hyperObject)
                                }
                            })

                            return merged
                        },
                    },
                },
            }
        }
    }),
    link:  from([
        authMiddleware,
        new HttpLink({
            uri:   `https://ttg.salzburgerland.com/pimcore-graphql-webservices/TourData`,
            fetch: fetch
        })
    ])
})
